import { Dispatch, SetStateAction, useState } from 'react';
import { DateTime } from "luxon";
import Customer from '../classes/Customer';
import User from '../classes/User';
import ArchivedLog from '../classes/ArchivedLog';
import AjaxFn, { ErrorResponse } from '../util/AjaxFn.ts';

export default function HistoryScreenViewModel(): IHistoryScreenViewModel{

	const now = DateTime.now();
	const from = now.startOf('month', {}).toISODate();
	const to = now.endOf('month', {}).toISODate();

	//set user is the individual user setting 
	const [user, setUser] = useState<User>({} as User);

	//sets all the users available for auto fill
	const [users, setUsers] = useState<Array<User>>([]);
	
	//searched users 
	const [usersearch, setUserSearched] = useState<User | null>(null);

	const [customers, setCustomers] = useState<Array<Customer>>([]);
	const [customer, setCustomer] = useState<Customer | null>(null);
	const [logs, setLogs] = useState<Array<ArchivedLog>>([]);
	const [fromDate, setFromDate] = useState<string>(from);
	const [toDate, setToDate] = useState<string>(to);
	const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
	const [showOnlyMine, setShowOnlyMine] = useState<boolean>(true);
	const [showDeleted, setShowDeleted] = useState<boolean>(false);
	const [showOnlyDeleted, setShowOnlyDeleted] = useState<boolean>(false);
	const [showOnlySusLogs, setShowOnlySusLogs] = useState<boolean>(false);
	const [sortOrder, setSortOrder] = useState<SortOrder>({order: 'caller', asc: true});

	const search = (error: (msg: string) => void) => {
		
		var fDate = DateTime.fromFormat(fromDate, 'yyyy-MM-dd');
		var tDate = DateTime.fromFormat(toDate, 'yyyy-MM-dd');
		
		//typescript doesn't like but this is valid
		if (!fDate || !tDate || isNaN(fDate) || isNaN(tDate)) {
			error("Invalid date range");
			// let today = DateTime.now().toFormat('yyyy-MM-dd');
			// let lastmonth  = DateTime.now().minus({months: 1}).toFormat('yyyy-MM-dd');
			// setFromDate(lastmonth);
			// setToDate(today);
			return;
		}
	
		if (tDate < fDate) {
			const tmp = fDate;
			fDate = tDate;
			tDate = tmp;
	
			setFromDate(fDate.toFormat('yyyy-MM-dd'));
			setToDate(tDate.toFormat('yyyy-MM-dd'));
		}
		
		let custCode = customer ?? null;
		let user = usersearch ?? null;
	
		AjaxFn.getHistoryLogs({
			
			fromDate: fDate.toFormat('yyyy-MM-dd'), 
			toDate: tDate.toFormat('yyyy-MM-dd'),   
			cust: custCode,
			user: user,
			callback: (rows: Array<ArchivedLog>) => {
				setLogs(rows);
			},
			errCallback: (response: ErrorResponse) => {
				error(response.data);
			},
			setRequestInProgress
		});
	};
	
	const setUserSearch = (newUser: User | null) => {
		//reset this 
		setShowOnlyMine(false);
		setUserSearched(newUser);
	}

	return {
		customers, setCustomers,

		setUserSearch,
		users, setUsers,
		setCustomer,
		logs,setLogs,
		fromDate, setFromDate,
		toDate, setToDate,
		requestInProgress, //setRequestInProgress,
		showOnlyDeleted, setShowOnlyDeleted,
		showOnlySusLogs, setShowOnlySusLogs,
		showOnlyMine, setShowOnlyMine,
		showDeleted, setShowDeleted,
		sortOrder, setSortOrder,
		search,
		user, setUser
	};

}


export interface IHistoryScreenViewModel{
	search: (error: (msg: string) => void) => void;
	sortOrder: SortOrder;
	setSortOrder: (newOrder: SortOrder) => void;
	showOnlyMine: boolean;
	showDeleted: boolean;
	showOnlyDeleted: boolean;
	showOnlySusLogs: boolean;
	logs: Array<ArchivedLog>;
	setLogs: Dispatch<SetStateAction<Array<ArchivedLog>>>;
	customers: Array<Customer>;
	setCustomers: Dispatch<SetStateAction<Array<Customer>>>;
	setCustomer: (newCustomer: Customer | null) => void;
	// setUsers: (newUsers: Array<User>) => void;
	users: Array<User>;
	setUserSearch: (newUser: User | null) => void;
	
	setUsers: Dispatch<SetStateAction<Array<User>>>;
	fromDate: string;
	toDate: string;
	setFromDate: (date: string) => void;
	setToDate: (date: string) => void;
	requestInProgress: boolean;
	setShowOnlyDeleted: (value: boolean) => void;
	setShowOnlySusLogs: (value: boolean) => void;
	setShowOnlyMine: (value: boolean) => void;
	setShowDeleted: (value: boolean) => void;
	user: User;
	setUser: (user: User) => void;
}

export interface SortOrder{
	order: string;
	asc: boolean;
}
