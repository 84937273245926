import AjaxUtil from "../../common/js/AjaxUtil.ts";
import ArchivedLog, { IArchivedLog } from "../classes/ArchivedLog.ts";
import Customer, { ICustomer } from "../classes/Customer.ts";
import User from "../classes/User.ts";
import CustomerGroup from "../classes/CustomerGroup.ts";
import NewLog, { INewLog } from "../classes/NewLog.ts";

export default class AjaxFn{
    
    static getSession(reqArgs: InitialLoadRequest){

        const { callback, errCallback, setRequestInProgress } = reqArgs;
        const args = { fn: 'get-session' };
        const cb = (resp: InitialLoadResponse) => {
            let { user, groups, users, customers, logs, completedlogs } = resp.data;

            const custArr = customers.map(c => new Customer(c));
            const logsArr: Array<NewLog> = [];
            logs.forEach(l => {
                const cust = custArr.find(c => c.code === l.l_cust);
                if (cust) logsArr.push(new NewLog(cust, l));
            });
            //now push the completed logs with a completed flag
            completedlogs.forEach(l => {
                const cust = custArr.find(c => c.code === l.l_cust);
                l.completed = true;
                if (cust) logsArr.push(new NewLog(cust, l));
            });

            users = users.map(u => new User(u));



            callback(user, groups, users, custArr, logsArr);
        };

        AjaxFn.postMySql({ args, callback: cb, errCallback, setRequestInProgress });
    }

    static getHistoryLogs(reqArgs: HistoryLogsRequest) {
        const { fromDate, toDate, cust, user, callback, errCallback, setRequestInProgress } = reqArgs;
      
        // Determine the cust code based on whether cust is a string or a Customer object
        const custCode = cust?.code ?? null;
        const userCode = user?.u_name ?? null;

        
        
        // Prepare the arguments for the AJAX function
        const args: any = { fn: 'get-all-archived-logs', fromDate, toDate };
        if (custCode) {
          args.cust = custCode;
        }
        if (userCode) {
          args.user = userCode;
        }
      
        const cb = (resp: ArrayDataResponse<IArchivedLog>) => {
          const logs = resp.data.map(log => new ArchivedLog(log));
          callback(logs);
        };
      
        AjaxFn.postMySql({ args, callback: cb, errCallback, setRequestInProgress });
      }
      
      

    static addLog(reqArgs: AddLogRequest){
        
        const { user, cust, callback, errCallback, setRequestInProgress } = reqArgs;
        const args = { fn: 'add-log', cust, user };
        const cb = (resp: AddLogResponse) => {
            callback(resp.data.id);
        };

        AjaxFn.postMySql({ args, callback: cb, errCallback, setRequestInProgress });
    }


    static submitLog(reqArgs: SaveLogRequest){
        
        const { user, id, caller, total, cust, problem, solution, start, finish, callback, errCallback, setRequestInProgress } = reqArgs;
        const args = { fn: 'submit-log', user, id, caller, total, cust, problem, solution, start, finish};

        AjaxFn.postMySql({ args, callback, errCallback, setRequestInProgress });

    }

    static saveLog(reqArgs: SaveLogRequest){

        const { user, id, caller, total, cust, problem, solution, start, finish, callback, errCallback, setRequestInProgress } = reqArgs;
        const args = { fn: 'update-log', user, id, caller, total, cust, problem, solution, start, finish };

        AjaxFn.postMySql({ args, callback, errCallback, setRequestInProgress });

    }

    static saveAndDeleteLog(reqArgs: SaveLogRequest){

        const { user, id, caller, total, cust, problem, solution, start, finish, callback, errCallback, setRequestInProgress } = reqArgs;
        const args = { fn: 'update-and-delete-log', user, id, caller, total, cust, problem, solution, start, finish};

        AjaxFn.postMySql({ args, callback, errCallback, setRequestInProgress });

    }

    static saveCustomerNotes(reqArgs: saveCustomerNotesRequest){

        const { cust, publicNote, privateNote, callback, errCallback, setRequestInProgress } = reqArgs;
        
        const args = { fn: 'set-customer-notes', cust, publicNote, privateNote };
        const cb = (response: BooleanResponse) => {
            if (response.success){
                callback();
            }else if (typeof response.data !== 'undefined'){
                errCallback({ success:false, data:response.data });
            }else{
                errCallback({ success:false, data:"Request failed" });
            }
        };

        AjaxFn.postMySql<BooleanResponse>({ args, callback: cb, errCallback, setRequestInProgress });
        
    }
    
    static postMySql<T>({
        args,
        callback,
        errCallback,
        setRequestInProgress
    } : MysqlRequest<T>){
        var url = 'ajax.php';
        var success = (json: JSON) => {
            try {
                callback(json as T);
            } catch (error) {
                try {
                    //errCallback(json as ErrorResponse)
                    errCallback({ success: false, data: "Request failed" })
                } catch (error) {
                    errCallback({ success: false, data: "Request failed" });
                }
            }
        };
        const error = (msg: string) => {
            errCallback({ success: false, data: msg });
        };
        AjaxUtil.fetchPost({ url, args, success, error, setRequestInProgress });
    }
    
    static postFirebase<T>({
        args,
        callback,
        setRequestInProgress
    } : FirebaseRequest<T>){
        var url = 'ajax.php';
        var success = (json: JSON) => callback(json as T);
        AjaxUtil.fetchPost({ url, args, success, setRequestInProgress });
    }

}

interface MysqlRequest<T> {
    args: object;
    callback: (response: T) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface FirebaseRequest<T> {
    args: object;
    callback: (response: T) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface InitialLoadResponse{
    data: {
        user: User;
        groups: Array<CustomerGroup>;
        users: Array<User>;
        customers: Array<ICustomer>;
        logs: Array<INewLog>;
        completedlogs: Array<INewLog>;
    };
}

interface HistoryLogsRequest{
    fromDate: string;
    toDate: string;
    cust: Customer | null;
    user: User | null;
    callback: (response: Array<ArchivedLog>) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface AddLogRequest{
    user: string;
    cust: string;
    callback: (response: number) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface AddLogResponse{
    success: boolean;
    data: {
        id: number;
    };
}

export interface SaveLogRequest{
    user: string;
    id: number;
    caller: string;
    total: number;
    cust: string;
    problem: string;
    solution: string;
    start: string;
    finish: string;
    callback: () => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface saveCustomerNotesRequest{
    cust: string;
    publicNote: string;
    privateNote: string;
    callback: () => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface InitialLoadRequest{
    callback: (user: User, groups: Array<CustomerGroup>, users: Array<User>, customers: Array<Customer>, logs: Array<NewLog>) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface ArrayDataRequest<T>{
    callback: (response: Array<T>) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface ArrayDataResponse<T>{
    data: Array<T>
}

export interface ErrorResponse{
    success: boolean;
    data: string;
    loginUrl?: string;
}

interface BooleanResponse{
    success: boolean;
    data?: string;
}