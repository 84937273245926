import React from "react";
import ErrorDiv from "../common/ErrorModal.jsx";
// import User from "../../js/classes/User.ts";
import UnassignUserGroup from "./modals/UnassignUserGroup.tsx";
import AssignUserGroupModal from "./modals/AssignUserGroup.tsx";
import CreateUserModal from "./modals/AddUser.tsx";
import UserDeleteModal from "./modals/DeleteUser.tsx";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported
import Loading from "../common/loading.tsx";
import { IUserTableViewModel} from "../../js/viewmodels/UsersScreenViewModel.ts";

interface IUserTableProps {
  model: IUserTableViewModel;
}

const UserTable: React.FC<IUserTableProps> = ({ model }) => {
  const {
    users,
    groups,
    selectedUsers,
    loading,
    error,
    showErrorDiv,
    actionResponse,
    responseMessage,
    filterUsers,
    handleSelectUser,
    setShowResponseDiv,
    handleUserUpdate,
    handleSelectAll,
    addNewUserCallback,
    handleUserDeleteCallback
  } = model;



  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="container">
        <ErrorDiv
          success={actionResponse}
          errorMessage={responseMessage}
          isVisible={showErrorDiv}
          onClose={() => setShowResponseDiv(false)}
        />
        <div className="container ">
          <h1 className="mb-4 text-left">User Management</h1>
          <div className="d-flex mb-3">
            <div className="form-group me-3">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={(e) => filterUsers(e.target.value)}
              />
            </div>
            <div className="ms-auto d-flex align-items-center">
              {selectedUsers.length > 0 && (
                <>
                  <UnassignUserGroup usersSelected={selectedUsers} callback={handleUserUpdate} hubgroups={groups} />
                  <AssignUserGroupModal usersSelected={selectedUsers} callback={handleUserUpdate} hubgroups={groups} />
                </>
              )}
              <CreateUserModal callback={addNewUserCallback} />
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        // checked={selectAll}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                      />
                    </th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Groups</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.u_name}>
                      <td>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={selectedUsers.some((selectedUser) => selectedUser.u_name === user.u_name)}
                          onChange={() => handleSelectUser(user)}
                        />
                      </td>
                      <td>{user.u_name}</td>
                      <td>{user.u_email}</td>
                      <td>{user.u_groups ? user.u_groups.join(", ") : ""}</td>
                      <td className="text-center">
                        <UserDeleteModal user={user} callback={handleUserDeleteCallback} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTable;
