import React, { useState } from "react";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";
import ErrorDiv from "../../common/ErrorModal";
import User from "../../../js/classes/User.ts";

interface UserCreationModalProps {
  callback: (isSuccess: boolean, newuser?: User, message?: string) => void;
}

const UserCreationModal: React.FC<UserCreationModalProps> = ({ callback }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClose = (isSuccess: boolean, newuser?: User) => {
    setShow(false);
    callback(isSuccess, newuser);
    // reset everything
    setShowResponseDiv(false);
    setName("");
    setEmail("");
    setValidated(false); // Unset the validated status
  };

  const handleShow = () => setShow(true);

  const displayError = (message: string) => {
    setShowResponseDiv(true);
    setResponseMessage(message || "");
    setIsSuccess(!message);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        const request = { name, email };
        const url = `/api/users/create/`;
        const result = await HttpClientUtil.postRequest(url, request, true);

        if (!result.success) {
          displayError(result.message);
        } else {
          displayError("");
          // create user 
          const newUser = new User({ u_name: name, u_email: email });
          handleClose(true, newUser);
        }
      } catch (error) {
        console.error("Error creating user:", error);
        displayError("An unexpected error occurred while creating the user.");
      }
    }

    setValidated(true);
  };

  const validateEmail = (email: string) => {
    return email.endsWith("@hubsystems.com.au");
  };

  return (
    <>
      <button className="btn btn-info mx-1" type="button" onClick={handleShow}>
        Create New User
      </button>

      {show && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create New User</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => handleClose(false)}></button>
              </div>

              <form noValidate onSubmit={handleSubmit}>
                <div className="modal-body">
                  <ErrorDiv
                    success={isSuccess}
                    errorMessage={responseMessage}
                    isVisible={showErrorDiv}
                    onClose={() => setShowResponseDiv(false)}
                  />
                  <div className="form-group mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={`form-control ${validated && !name ? "is-invalid" : ""}`}
                        id="name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <label htmlFor="name">Name</label>
                      <div className="invalid-feedback">Please enter the user's name.</div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="form-floating">
                      <input
                        type="email"
                        className={`form-control ${validated && !validateEmail(email) ? "is-invalid" : ""}`}
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <label htmlFor="email">Email</label>
                      <div className="invalid-feedback">
                        Please enter a valid email ending with @hubsystems.com.au.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => handleClose(false)}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Create User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserCreationModal;
