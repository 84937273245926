import React, { ReactElement, useRef, useState, KeyboardEvent } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import Customer from '../js/classes/Customer.ts';

export default function Autocomplete({
	id="",
	name="",
	label="",
	labelKey="text",
	autoFocus=false,
    forceSelection=true,
    datalist=[],
    placeholder="",
    setValue,
    clearOnComplete=false,
    defaultValue=undefined
}: IAutocomplete){
    const [singleSelections, setSingleSelections] = useState<Array<Customer>>(defaultValue ? [defaultValue] : []);
    const [filteredList, setFilteredList] = useState<Array<Customer>>([]);
    const [filterText, setFilterText] = useState('');

	// const inputReference = useRef<HTMLInputElement | null>(null);

	const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter'){
            var fullMatch = datalist.find(f => f[labelKey] === e.currentTarget.value);
            if (fullMatch){
                // Let the component handle it
                return;
            }

            if (filteredList.length > 1){
                var selection: Customer;
                var exactMatch = filteredList.find(f => f.code.toLowerCase() === filterText);
                if (filteredList.length === 1){
                    selection = filteredList[0];
                }else if (typeof exactMatch !== 'undefined'){
                    selection = exactMatch;
                }else{
                    selection = filteredList[0];
                }
                addNewLog(selection);
            }
		}
	}
    const onSelect = (newSelections: Array<Customer>) => {
        if (newSelections.length > 0)
            addNewLog(newSelections[0]);
        else{
            setSingleSelections(newSelections);


            //default to true to force a valid customer
            //allows customers to be unselected without the clearonComplete option
            if (!forceSelection){
                setValue(Customer.createEmpty());
            }
        }
    };
    const addNewLog = (cust: Customer) => {
        setValue(cust);
        if (clearOnComplete){
            setSingleSelections([]);  // Clear the selection
            setFilterText('');        // Clear the text filter
            setFilteredList([]);      // Reset the filtered list
        } else {
            setSingleSelections([cust]);  // Set the selected customer
        }
    };
    const onInputChange = (text: string) => {
        var lowerText = text.toLowerCase();
        setFilterText(lowerText);
        var filtered = datalist.filter(i => i[labelKey].toLowerCase().includes(lowerText));
        setFilteredList(filtered);
        
        return false;
    };

	var span: ReactElement;
	var divClass = "input-group mb-3";
	if (name.length > 0){
		span = (<span className="input-group-text">{name}</span>);
	}else if (label.length > 0){
		divClass = "mb-3";
		span = (<label className="small">{label}</label>);
	}else{
		span = (<></>);
	}

	return (
		<div className={divClass}>
			{span}
			<Typeahead
                id={id}
                labelKey={labelKey}
                options={datalist}
                placeholder={placeholder}
                selected={singleSelections}
                // ref={inputReference}
                autoFocus={autoFocus}
                forceSelection={forceSelection}
                onKeyDown={onKeyPress}
                onChange={onSelect}
                onInputChange={onInputChange}
                highlightOnlyResult={true}
                minLength={1}
                // defaultInputValue={defaultValue}
                />
		</div>
	);

}

interface IAutocomplete {
    id?: string;
    name?: string;
    label?: string;
    labelKey?: string;
    autoFocus?: boolean;
    forceSelection?: boolean;
    datalist?: Array<Customer>;
    placeholder?: string;
    setValue: (newValue: Customer | null) => void;
    clearOnComplete?: boolean;
    defaultValue?: Customer;
    selected?: Customer;  
  }