import React, { useState } from "react";
import { IAdminScreenViewModel } from "../js/viewmodels/AdminScreenViewModel.ts";
import { Collapse, Button, Form, Table } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaFileUpload, FaEnvelope } from "react-icons/fa";
import ConfirmationModal from "./common/ConfirmationModal.jsx";
import ErrorDiv from "./common/ErrorModal.jsx";
import LoadingScreen from "./common/loading.tsx";
import AskfordataModal from "./common/AskforEndofPeriod.jsx";

const AdminPanel: React.FC<IAdminScreen> = (args: IAdminScreen) => {
  const { model } = args;
  
  // Group customers by their group property
  const groupedCustomers = model.customers.reduce((acc, customer) => {
    const group = customer.group || "Ungrouped"; // Handle customers with no group
    if (!acc[group]) acc[group] = [];
    acc[group].push(customer);
    return acc;
  }, {});

  return (
    <>
      <ErrorDiv
        success={model.isSuccess}
        errorMessage={model.responseMessage}
        isVisible={model.showErrorDiv}
        onClose={() => model.setShowResponseDiv(false)}
      />
      <ConfirmationModal
        show={model.showConfirmModal}
        onConfirm={model.handleConfirm}
        onCancel={() => model.setShowConfirmModal(false)}
        title="Confirm End of Month"
        message="Are you sure you want to run End of Month processes?"
      />
      <AskfordataModal
        show={model.showEndOfMonthModal}
        onConfirm={model.endOfMonth}
        onCancel={() => model.setShowEndOfMonthModal(false)}
        Title="Enter the End of Month Period"
      />
      <LoadingScreen loading={model.showLoadingScreen} />
      <div className="card">
        <div className="card-header">Admin Panel</div>
        <div className="card-body">
          <h5 className="card-title">End of Month Actions</h5>
          <button onClick={model.handleEndOfMonthPrompt} className="btn btn-danger">
            Run End of Month
          </button>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-header">
          <h5>Customer Management</h5>
        </div>
        <div className="card-body">
          {Object.keys(groupedCustomers).map((group) => (
            <CustomerGroup key={group} group={group} customers={groupedCustomers[group]} />
          ))}
        </div>
      </div>
    </>
  );
};

// Component to display each group of customers
const CustomerGroup: React.FC<{ group: string, customers: any[] }> = ({ group, customers }) => {
  const [open, setOpen] = useState(false);  // State to manage collapse
  const [useDefaultEmail, setUseDefaultEmail] = useState(true);
  const [emailContent, setEmailContent] = useState<string>("");

  const handleSendAll = () => {
    // Logic to send email to all customers in the group
    console.log(`Sending email to all customers in ${group}: ${emailContent}`);
  };

  return (
    <div className="customer-group mb-3">
      <Button
        variant="link"
        className="group-header"
        onClick={() => setOpen(!open)}
        aria-controls={`collapse-${group}`}
        aria-expanded={open}
      >
        {open ? <FaChevronUp /> : <FaChevronDown />} {group} ({customers.length} customers)
      </Button>

      <Collapse in={open}>
        <div id={`collapse-${group}`} className="group-body mt-2">
          <Form.Check 
            type="checkbox" 
            label="Use Default Email" 
            checked={useDefaultEmail} 
            onChange={() => setUseDefaultEmail(!useDefaultEmail)} 
          />
          {!useDefaultEmail && (
            <Form.Group controlId={`emailForm-${group}`} className="mb-2">
              <Form.Label>
                <FaEnvelope /> Custom Email Content
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={emailContent}
                onChange={(e) => setEmailContent(e.target.value)}
                placeholder="Enter your email message here..."
                className="mb-2"
              />
            </Form.Group>
          )}
          <Button
            variant="primary"
            onClick={handleSendAll}
            disabled={!useDefaultEmail && !emailContent}
            className="mb-3"
          >
            Send All
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer) => (
                <CustomerItem key={customer.code} customer={customer} />
              ))}
            </tbody>
          </Table>
        </div>
      </Collapse>
    </div>
  );
};

// Component to display each customer and their actions
const CustomerItem: React.FC<{ customer: any }> = ({ customer }) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <tr>
      <td>{customer.name}</td>
      <td>
        <Form.Group controlId={`fileUpload-${customer.code}`} className="mb-2">
          <Form.Label>
            <FaFileUpload /> Upload File
          </Form.Label>
          <Form.Control
            type="file"
            onChange={handleFileUpload}
          />
          {file && <small>Selected file: {file.name}</small>}
        </Form.Group>
      </td>
    </tr>
  );
};

export default AdminPanel;

interface IAdminScreen {
  model: IAdminScreenViewModel;
}