import React, { useState, useEffect } from "react";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";
import ErrorDiv from "../../common/ErrorModal";
import User from "../../../js/classes/User.ts";
import CustomerGroup from "../../../js/classes/CustomerGroup.ts";


interface UserGroupAssignmentModalProps {
  usersSelected: User[];
  hubgroups: CustomerGroup[];
  callback: (updatedUsers: User[], isSuccess: boolean) => void;
}

const UserGroupAssignmentModal: React.FC<UserGroupAssignmentModalProps> = ({ usersSelected, hubgroups, callback }) => {
  const [show, setShow] = useState(false);
  const [groups, setGroups] = useState<CustomerGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<CustomerGroup | null>(null);
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleClose = (isSuccess: boolean) => {
    setShowResponseDiv(false);
    setShow(false);
    setSelectedGroup(null);
    callback(usersSelected, isSuccess);
  };

  const handleShow = () => {
    setShow(true);
    const groupss = hubgroups.filter(group => usersSelected.some(user => user.u_groups.includes(group.g_name))); 
    setGroups(groupss);
    
  };


  const displayError = (message: string) => {
    setShowResponseDiv(true);
    setResponseMessage(message || "");
  };

  const handleUnassign = async () => {
    if (!selectedGroup) {
      displayError("Please select a group to unassign.");
      return;
    }

    try {
      const url = '/api/groups/assign/users';
      const payload = {
        groupId: selectedGroup.g_id,
        users: usersSelected.map(user => user.u_name),
      };

      const result = await HttpClientUtil.deleteRequest(url, payload);

      if (!result.success) {
        displayError(result.message);
        handleClose(false);  // Close with a failure
        return;
      }

      // If successful, remove the group from each user in usersSelected
      
      usersSelected.forEach(user =>{
        user.removeGroup(selectedGroup.g_name);
      });

      handleClose(true);  // Close with success
    } catch (error) {
      console.error("Error unassigning users from group:", error);
      displayError("Failed to unassign users from group.");
      handleClose(false);  // Close with a failure
    }
  };

  return (
    <>
      <button className="btn btn-danger me-2" type="button" onClick={handleShow}>
        Unassign Group
      </button>

      {show && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Unassign Users from Group</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => handleClose(false)}></button>
              </div>

              <div className="modal-body">
                <ErrorDiv
                  success={false}
                  errorMessage={responseMessage}
                  isVisible={showErrorDiv}
                  onClose={() => setShowResponseDiv(false)}
                />

                <div className="list-group mb-3">
                  {groups.map((group) => (
                    <button
                      type="button"
                      className={`list-group-item list-group-item-action ${selectedGroup && selectedGroup.g_id === group.g_id ? "active" : ""}`}
                      key={group.g_id}
                      onClick={() => setSelectedGroup(group)}
                    >
                      <div>
                        <strong>{group.g_name}</strong>
                        <br />
                        <small>{group.g_description}</small>
                      </div>
                    </button>
                  ))}
                </div>

                <div className="form-group">
                  <label>Selected Users:</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    readOnly
                    value={usersSelected.map(user => user.u_name).join(", ")}
                  />
                </div>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => handleClose(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-danger" onClick={handleUnassign}>
                  Unassign
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserGroupAssignmentModal;
