export interface ICustomer {

  C_DIALCODE: string;
    C_NAME: string;
    usedTotal: string;
    customerGroups: string[];
  }
  
  class MaintenanceCustomer implements ICustomer {
    C_DIALCODE: string;
    C_NAME: string;
    usedTotal: string;
    customerGroups: string[];
  
    constructor(
      Customer: ICustomer
    ) {
      this.C_DIALCODE = Customer.C_DIALCODE;
      this.C_NAME = Customer.C_NAME;
      this.usedTotal = Customer.usedTotal;
      this.customerGroups = Customer.customerGroups || [];
    }
  
  
  }
  
  export default MaintenanceCustomer;
  