import React from 'react';
import { IUserSettingsViewModel } from '../../../js/viewmodels/UserSettingsViewModel';

export default function SettingsModal (args: ISettingsModal) {
  const { viewModel } = args;

  if (!viewModel.user) {
    return <></>;
  }

  const handleCheckboxChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    viewModel.setUserSettings({ [name]: e.target.checked });
  };

  const cancel = () => {
    viewModel.hide();
  }

  const submit = () => {
    viewModel.submit();
  }

  return (
    <div id="settingsModal" className="modal show" tabIndex={-1} role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-center">Settings for {viewModel.user ? viewModel.user.u_name : 'User'}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={cancel} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {[
              // { name: 'compact', label: 'Compact Mode', value: viewModel.userSettings.compact },
              // { name: 'hideSidebar', label: 'Hide Sidebar', value: viewModel.userSettings.hideSidebar },
              { name: 'darkMode', label: 'Dark Mode', value: viewModel.userSettings.darkMode },
              // { name: 'enableNotes', label: 'Accordion Card Layout', value: viewModel.userSettings.enableNotes }
            ].map((setting) => (
              <div className="form-check" key={setting.name}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={setting.name}
                  checked={setting.value}
                  onChange={handleCheckboxChange(setting.name)}
                />
                <label className="form-check-label" htmlFor={setting.name}>
                  {setting.label}
                </label>
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={cancel}>Close</button>
            <button type="button" className="btn btn-primary" onClick={submit}>Save changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ISettingsModal {
  viewModel: IUserSettingsViewModel;
}
