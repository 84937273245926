import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import ErrorDiv from "../../common/ErrorModal.jsx";
import { HttpClientUtil } from '../../common/HttpClientUtil.ts';

interface CustomerDeleteModalProps {
  custcode: string | undefined;
  callback: (isSuccess: boolean) => void;
}

const CustomerDeleteModal: React.FC<CustomerDeleteModalProps> = ({ custcode, callback }) => {
  const [show, setShow] = useState(false);
  const [dialCode, setDialCode] = useState('');
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClose = (isSuccess: boolean) => {
    // Reset everything
    setShowResponseDiv(false);
    setShow(false);
    setDialCode('');
    callback(isSuccess);
  };

  const handleShow = () => setShow(true);

  const displayError = (message: string) => {
    setShowResponseDiv(true);
    if (!message) {
      setResponseMessage("");
      setIsSuccess(true);
    } else {
      setResponseMessage(message);
      setIsSuccess(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (!dialCode || dialCode.length === 0) {
        displayError("Please enter dial code");
        return;
      }
      if (custcode !== dialCode) {
        displayError("Customer code does not match");
        return;
      }

      const url = `/api/deletecustomer/${dialCode}`;
      const result = await HttpClientUtil.deleteRequest(url, {}, true);

      if (!result.success) {
        displayError(result.message);
      } else {
        displayError('');
        handleClose(true);
      }

    } catch (error) {
      console.error('Error deleting customer:', error);
      displayError("Failed to delete customer.");
    }
  };

  return (
    <>
      <button className="btn btn-danger mx-2" type="button" style={{ float: "right" }} onClick={handleShow}>
        Delete Current Customer
      </button>

      {show && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Enter The Customer Code: <br />To Confirm Deletion
                </h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => handleClose(false)}></button>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <ErrorDiv
                    success={isSuccess}
                    errorMessage={responseMessage}
                    isVisible={showErrorDiv}
                    onClose={() => setShowResponseDiv(false)}
                  />
                  <div className="mb-3">
                    <label htmlFor="dialCode" className="form-label">Customer Code:</label>
                    <input
                      type="text"
                      id="dialCode"
                      className="form-control"
                      value={dialCode}
                      onChange={(e) => setDialCode(e.target.value)}
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => handleClose(false)}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-danger">
                    Confirm Deletion
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerDeleteModal;
